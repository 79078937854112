import React from 'react';
import LazyLoad from 'react-lazyload';
import milaPanVideo from 'assets/images/mila-pan-video.mp4';
import loadable from '@loadable/component';

const FadeIn = loadable(() => import('../common/FadeIn'));

const VideoBlock = () => (
  <section className='video-block'>
    <div className='container'>
      <div className='video-block__content'>
        <h2 className='video-block__title'>Finally, an air purifier with genuine smarts.</h2>
        <h3 className='video-block__subtitle'>
          Let’s face it, today’s air purifiers are outdated appliances wrapped in
          sketchy marketing.
        </h3>
        <div className='video-block__description'>
          <p>
            Most still have 3 antiquated speeds—like a blender circa 1984.
            Mila is different. She’s intuitive, honest,
            and is made with more thoughtfulness than grandma's apple pie.
          </p>
        </div>
      </div>
    </div>
    <div className='video-wrapper'>
      <div className='video-container'>
        <LazyLoad>
          <FadeIn>
            <video
              id='mila-video'
              className='thumbnail-block__video'
              playsInline
              webkit-playsinline='true'
              autoPlay
              muted
            >
              <source src={milaPanVideo} type='video/mp4' />
            </video>
          </FadeIn>
        </LazyLoad>
      </div>
    </div>
  </section>
);

export default VideoBlock;
